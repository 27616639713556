<template>
  <div class="contextBox">
    <div class="head">
      <div class="head_item">
        <div class="head_left">
          <div class="head_title">总分销</div>
          <div class="head_sum">{{ selectLookCountList.totalLookNumber }}</div>
        </div>

        <div class="head_right">
          <div class="head_img sum_distribution">
            <img src="../../../static/images/Group 2609.png" alt="" />
          </div>
          <div class="head_sum">
            <span class="head_h2Title">今日新增</span>
            <span class="head_newAdd">{{ selectLookCountList.totalTodayLookNumber }}</span>
            <i class="el-icon-top"></i>
          </div>
        </div>
      </div>
      <div class="head_item">
        <div class="head_left">
          <div class="head_title">活动分销</div>
          <div class="head_sum">{{ selectLookCountList.activityLookNumber }}</div>
        </div>

        <div class="head_right">
          <div class="head_img activity_distribution">
            <img src="../../../static/images/Group 2610.png" alt="" />
          </div>
          <div class="head_sum">
            <span class="head_h2Title">今日新增</span>
            <span class="head_newAdd">{{ selectLookCountList.activityTodayLookNumber }}</span>
            <i class="el-icon-top"></i>
          </div>
        </div>
      </div>
      <div class="head_item">
        <div class="head_left">
          <div class="head_title">需求分销</div>
          <div class="head_sum">{{ selectLookCountList.demandLookNumber }}</div>
        </div>

        <div class="head_right">
          <div class="head_img demand_distribution">
            <img src="../../../static/images/Group 2611.png" alt="" />
          </div>
          <div class="head_sum">
            <span class="head_h2Title">今日新增</span>
            <span class="head_newAdd">{{ selectLookCountList.demandTodayLookNumber }}</span>
            <i class="el-icon-top"></i>
          </div>
        </div>
      </div>
      <div class="head_item">
        <div class="head_left">
          <div class="head_title">报备分销</div>
          <div class="head_sum">{{ selectLookCountList.supplyLookNumber }}</div>
        </div>

        <div class="head_right">
          <div class="head_img report_distribution">
            <img src="../../../static/images/Group 2612.png" alt="" />
          </div>
          <div class="head_sum">
            <span class="head_h2Title">今日新增</span>
            <span class="head_newAdd">{{ selectLookCountList.supplyTodayLookNumber }}</span>
            <i class="el-icon-top"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="context">
      <div class="context_inquire">
        <!-- 分销类别 -->
        <div class="category">
          <span class="leb">分销类别：</span>
          <span class="span_button" v-for="item in categoryList" :key="item.id">
            <el-button
              :class="categoryActive == item.id ? 'active' : ''"
              @click="clickCategory(item)"
              >{{ item.value }}</el-button
            >
          </span>
        </div>
        <!-- 用户等级 -->
        <div class="Grade">
          <span class="leb">用户等级：</span>
          <span class="span_button" v-for="item in GradeList" :key="item.id">
            <el-button :class="GradeActive == item.id ? 'active' : ''" @click="clickGrade(item)">
              {{ item.value }}
            </el-button>
          </span>
        </div>
        <!-- 有效时间 -->
        <div class="time">
          <span class="leb">有效时间：</span>
          <el-date-picker
            v-model="timeVal"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始"
            end-placeholder="结束"
            prefix-icon="el-icon-date"
            @change="timeChange"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </div>
      </div>

      <div class="extract_search">
        <!-- 导出 -->
        <div class="extract" @mouseleave="mouseleaveExport">
          <el-button
            :class="extractClickButton == true ? 'deep_button' : 'pure_button'"
            @click="clickExport"
          >
            <img
              v-if="extractClickButton"
              src="../../../static/images/jr-icon-out-copy.png"
              alt=""
            />
            <img v-else src="../../../static/images/jr-icon-out-copy (1).png" alt="" />

            批量导出
          </el-button>
        </div>
        <!-- 搜索 -->
        <div class="search">
          <el-input
            v-model="inputVal"
            placeholder="请输入用户昵称，手机号查询"
            clearable
          ></el-input>
          <el-button @click="searchClick" class="deep_button">搜索</el-button>
        </div>
      </div>

      <div class="table">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :header-cell-style="{ background: '#F6F6F6', color: '#333333' }"
          :row-key="(row) => row.id"
          @sort-change="changeTableSort"
        >
          <el-table-column :reserve-selection="true" type="selection" width="55" align="center">
          </el-table-column>
          <el-table-column label="编号" align="center" prop="编号">
            <template slot-scope="scope">{{ scope.row.serialNumber }}</template>
          </el-table-column>
          <el-table-column
            label="分销转化率"
            sortable="custom"
            width="130"
            align="center"
            prop="分销转化率"
          >
            <template slot-scope="scope">
              <el-progress
                type="circle"
                :percentage="scope.row.distributionPercentConversion || 0"
                color="#7C71E1"
                :width="43"
                :hieght="43"
                :stroke-width="4"
              ></el-progress>
            </template>
          </el-table-column>
          <el-table-column label="头像" align="center" prop="头像">
            <template slot-scope="scope">
              <img
                style="
                   {
                    width: 48px;
                    height: 48px;
                    border-radius: 4px;
                  }
                "
                :src="scope.row.headPortrait"
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column label="姓名" align="center" prop="姓名">
            <template slot-scope="scope">{{ scope.row.userName }}</template>
          </el-table-column>

          <el-table-column label="手机号" align="center" width="140" prop="手机号">
            <template slot-scope="scope">{{ scope.row.mobile }}</template>
          </el-table-column>

          <el-table-column label="用户等级" align="center" prop="用户等级">
            <template slot-scope="scope">
              <div v-if="scope.row.whetherMember === 1">普通会员</div>
              <div v-if="scope.row.whetherMember === 5">超级会员</div>
            </template>
          </el-table-column>

          <el-table-column label="分销类别" align="center" prop="分销类别">
            <template slot-scope="scope">
              <div v-if="scope.row.distributionType === 0">活动分销</div>
              <div v-if="scope.row.distributionType === 1">需求分销</div>
              <div v-if="scope.row.distributionType === 2">报备分销</div>
            </template>
          </el-table-column>

          <el-table-column label="链接失效时间" width="130" align="center" prop="链接失效时间">
            <template slot-scope="scope">
              <div>{{ scope.row.startTime }}至</div>
              <div>{{ scope.row.endTime }}</div>
            </template>
          </el-table-column>

          <el-table-column
            label="查看人数"
            sortable="custom"
            align="center"
            width="110"
            prop="查看人数"
          >
            <template slot-scope="scope">{{ scope.row.lookNumber }}</template>
          </el-table-column>

          <el-table-column
            label="想联系人数"
            sortable="custom"
            width="130"
            align="center"
            prop="想联系人数"
          >
            <template slot-scope="scope">{{ scope.row.thinkContactNumber }}</template>
          </el-table-column>

          <el-table-column
            label="申请会员人数"
            sortable="custom"
            width="130"
            align="center"
            prop="申请会员人数"
          >
            <template slot-scope="scope">{{ scope.row.applyMemberNumber }}</template>
          </el-table-column>

          <el-table-column
            label="报名人数"
            sortable="custom"
            align="center"
            width="110"
            prop="报名人数"
          >
            <template slot-scope="scope">{{ scope.row.applyNumber }}</template>
          </el-table-column>

          <!-- <el-table-column
            label="签到人数"
            sortable="custom"
            align="center"
            width="110"
            prop="签到人数"
          >
            <template slot-scope="scope">{{ scope.row.signInNumber }}</template>
          </el-table-column> -->

          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <div class="to_distribution_list" @click="ToDistributionList(scope.row)">
                分销列表
              </div>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="Pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="querySearch.current"
            :page-sizes="[10, 30, 50]"
            :page-size="querySearch.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { selectLookCount, selectDistribution, exportExcelAll } from '../../api/distribution'
export default {
  components: {},
  data() {
    //这里存放数据
    return {
      selectLookCountList: {},
      // 分销类别
      categoryList: [
        {
          value: '全部',
          id: 999
        },
        {
          value: '活动分销',
          id: 0
        },
        {
          value: '需求分销',
          id: 1
        },
        {
          value: '报备分销',
          id: 2
        }
      ],
      // 用户等级
      GradeList: [
        {
          value: '全部',
          id: 999
        },
        {
          value: '普通会员',
          id: 1
        },
        {
          value: '超级会员',
          id: 5
        }
      ],

      timeVal: [], // 有效时间
      GradeActive: 999, // 用户等级
      categoryActive: 999, // 分销类别
      extractClickButton: false, // 元素的类名控制
      inputVal: '', // 请输入用户昵称，手机号查询的vak
      tableData: [], //表格数据
      multipleSelection: [], // 按钮选中的数据
      querySearch: {
        // 表格调用接口要发起的数据
        current: 1,
        distributionType: null,
        endTime: '',
        nameOrMobile: '',
        size: 10,
        startTime: '',
        typeId: 0,
        whetherMember: null,
        order: '',
        orderType: null
      },
      total: 0, // 分页总条数
      tableIdList: []
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 最上面那部分
    async getSelectLookCount() {
      const { data: res } = await selectLookCount()
      if (res.resultCode === 200) {
        this.selectLookCountList = res.data
      }
      // console.log(this.selectLookCountList)
    },

    // 分销类别
    clickCategory(item) {
      // console.log(item);
      if (item.id == 999) {
        this.categoryActive = item.id
        this.querySearch.distributionType = null
        this.search()
      } else {
        this.categoryActive = item.id
        this.querySearch.distributionType = this.categoryActive
        this.search()
      }
    },
    // 用户等级
    clickGrade(item) {
      if (item.id == 999) {
        this.GradeActive = item.id
        this.querySearch.whetherMember = null
        this.search()
      } else {
        this.GradeActive = item.id
        this.querySearch.whetherMember = this.GradeActive
        this.search()
      }
    },
    // 有效时间
    timeChange() {
      if (this.timeVal !== null) {
        this.querySearch.startTime = this.timeVal[0]
        this.querySearch.endTime = this.timeVal[1]
        this.search()
      } else if (this.timeVal == null) {
        this.timeVal = []
        this.querySearch.startTime = ''
        this.querySearch.endTime = ''
        this.search()
      }
    },
    // 批量导出
    async clickExport() {
      // 判断有没有勾选数据
      if (!this.tableIdList.length > 0) {
        return this.$message.warning('请勾选数据')
      }
      this.extractClickButton = true
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { data: res } = await exportExcelAll({
        id: 0,
        idList: this.tableIdList
      })
      if (res) {
        loading.close()
        let blob = new Blob([res])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        a.href = href // 指定下载'链接
        a.download = '分销人员列表' + '.xlsx' //指定下载文件名
        // a.download = "test.png";
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
      } else {
        this.$message.error('导出失败!')
        loading.close()
      }
    },
    // 鼠标移开批量导出元素
    mouseleaveExport() {
      this.extractClickButton = false
    },
    // 点击搜索
    searchClick() {
      this.querySearch.nameOrMobile = this.inputVal
      this.search()
      // console.log('我是搜索框的文本:  ' + this.inputVal)
    },
    //表格按钮点击选中事件
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.tableIdList = []
      this.multipleSelection.forEach((item) => {
        this.tableIdList.push(item.id)
      })
      // console.log(this.tableIdList)
    },
    // 表格数据
    async search() {
      const { data: res } = await selectDistribution(this.querySearch)
      if (res.resultCode === 200) {
        this.tableData = res.data.list
        this.total = res.data.total
      }
      // console.log(this.tableData)
      // console.log(res)
    },
    // 分销列表  点击跳转
    ToDistributionList(row) {
      let path = ''
      // console.log(row)
      if (row.distributionType === 0) {
        //  活动分销
        path = '/distribution/activityDistribution'
      } else if (row.distributionType === 1) {
        //  需求分销
        path = '/distribution/demandDistribution'
      } else if (row.distributionType === 2) {
        // 报备分销
        path = '/distribution/filingDistribution'
      }
      // sessionStorage.setItem('routeRow', JSON.stringify(row))
      // console.log(row)
      this.$router.push({
        path: path,
        query: { query: JSON.stringify(row) }
      })
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.querySearch.size = val
      this.search()
    },
    // 分页
    handleCurrentChange(val) {
      this.querySearch.current = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    // 表格排序
    changeTableSort(column) {
      // console.log(column.prop, 'prop')
      // console.log(column.order, 'order')
      if (column.order == null) {
        this.querySearch.order = ''
        this.querySearch.orderType = null
        this.search()
      }
      if (column.prop == '分销转化率' && column.order == 'ascending') {
        this.querySearch.order = 'asc'
        this.querySearch.orderType = 0
        this.search()
      } else if (column.prop == '分销转化率' && column.order == 'descending') {
        this.querySearch.order = 'desc'
        this.querySearch.orderType = 0
        this.search()
      }

      if (column.prop == '查看人数' && column.order == 'ascending') {
        this.querySearch.order = 'asc'
        this.querySearch.orderType = 1
        this.search()
      } else if (column.prop == '查看人数' && column.order == 'descending') {
        this.querySearch.order = 'desc'
        this.querySearch.orderType = 1
        this.search()
      }

      if (column.prop == '想联系人数' && column.order == 'ascending') {
        this.querySearch.order = 'asc'
        this.querySearch.orderType = 2
        this.search()
      } else if (column.prop == '想联系人数' && column.order == 'descending') {
        this.querySearch.order = 'desc'
        this.querySearch.orderType = 2
        this.search()
      }

      if (column.prop == '申请会员人数' && column.order == 'ascending') {
        this.querySearch.order = 'asc'
        this.querySearch.orderType = 3
        this.search()
      } else if (column.prop == '申请会员人数' && column.order == 'descending') {
        this.querySearch.order = 'desc'
        this.querySearch.orderType = 3
        this.search()
      }

      if (column.prop == '报名人数' && column.order == 'ascending') {
        this.querySearch.order = 'asc'
        this.querySearch.orderType = 4
        this.search()
      } else if (column.prop == '报名人数' && column.order == 'descending') {
        this.querySearch.order = 'desc'
        this.querySearch.orderType = 4
        this.search()
      }
    }
  },

  created() {
    this.getSelectLookCount()
    this.search()
  },

  mounted() {}
}
</script>
<style scoped lang="less">
.contextBox {
  box-sizing: border-box;
  padding: 16px 14px 0 24px;

  .head {
    width: 100%;
    height: 165px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .head_item {
      width: 400px;
      height: 165px;
      background-color: #fff;
      border-radius: 12px;
      display: flex;
      .head_left {
        width: 50%;
        height: 100%;
        color: #333;
        margin-left: 34px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .head_title {
          font-size: 32px;
        }
        .head_sum {
          font-size: 24px;
        }
      }

      .head_right {
        box-sizing: border-box;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;
        padding-right: 42px;
        .head_img {
          width: 89px;
          height: 89px;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .head_sum {
          // display: flex;

          .head_h2Title {
            font-size: 10px;
            text-align: bottom;
          }
          .head_newAdd {
            margin: 0 6px;
            font-size: 24px;
          }
        }
      }
      .head_right i {
        font-size: 21px;
        font-weight: 700;
        color: #ff6c66;
      }
    }
  }
  .context {
    box-sizing: border-box;
    width: 100%;
    // height: 100%;
    background-color: #fff;
    border-radius: 12px;
    padding: 0 16px 0 20px;
    .context_inquire {
      width: 100%;
      height: 64px;
      border-bottom: 1px dashed #ededed;
      display: flex;
      align-items: center;

      .category {
        .leb {
          font-size: 16px;
        }
        .span_button {
          margin-right: 15px;

          .el-button {
            font-size: 14px;
          }
        }
      }
      .Grade {
        margin: 0 60px 0 70px;
        .leb {
          font-size: 16px;
        }
        .span_button {
          margin-right: 15px;

          .el-button {
            font-size: 14px;
          }
        }
      }
    }

    .extract_search {
      box-sizing: border-box;
      width: 100%;
      height: 65px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 50px;

      .extract {
        img {
          width: 16px;
          height: 16px;
          margin-right: 3px;
        }

        /deep/ .el-button span {
          display: flex;
          align-items: center;
        }
      }
      .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /deep/.el-input__inner {
          width: 382px;
          height: 32px;
        }
        /deep/.el-input__suffix {
          display: flex;
          align-items: center;
        }
        /deep/.el-button {
          margin-left: 16px;
        }
      }
    }

    .table {
      width: 100%;
      // height: 100%;
      padding-bottom: 80px;
      position: relative;

      .to_distribution_list {
        color: #7c71e1;
        cursor: pointer;
      }
      .Pagination {
        width: 100%;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;

        /deep/.active {
          width: 28px;
          height: 28px;
          border-radius: 2px;
          background-color: #7c71e1;
          color: #fff;
          font-size: 12px;
          min-width: 28px;
          font-weight: 400;
        }
      }
    }
  }

  .sum_distribution {
    background-color: rgba(124, 113, 225, 0.1);
  }
  .activity_distribution {
    background-color: rgba(238, 102, 102, 0.1);
  }
  .demand_distribution {
    background-color: #ecf6f1;
  }
  .report_distribution {
    background-color: rgba(115, 192, 222, 0.1);
  }

  .active {
    color: #fff !important;
    background-color: #8598ff !important;
  }

  .span_bottom {
    margin-right: 15px;
  }
  .pure_button {
    border-radius: 4px;
    border: 1px solid #7c71e1;
    color: #7c71e1;
    font-size: 14px;
  }
  .pure_button:hover {
    border-radius: 4px;
    border: 1px solid #7c71e1;
    color: #7c71e1;
    background-color: rgba(124, 113, 225, 0.2);
    font-size: 14px;
  }

  .deep_button {
    border-radius: 4px;
    color: #fff;
    background-color: #7c71e1;
    font-size: 14px;
  }
}
</style>